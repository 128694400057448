import React, { createContext, Component } from 'react'
import config from 'config'
import superagent from 'superagent'

export const KMScheduledBroadcastContext = createContext({
  scheduledBroadcastStore: {}, // store all the scheduledBroadcast details
  updateScheduledBroadcastStore: () => { },
  initScheduledBroadcastStore: () => { },
  reloadScheduledBroadcastStore: () => { },
  scheduledBroadcastStoreRightPermissionDenied: true
})

export class KMScheduledBroadcastProvider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      scheduledBroadcastStore: {}, // store all the scheduledBroadcast details
      updateScheduledBroadcastStore: this.updateScheduledBroadcastStore,
      initScheduledBroadcastStore: this.initScheduledBroadcastStore,
      reloadScheduledBroadcastStore: this.reloadScheduledBroadcastStore,
      scheduledBroadcastStoreRightPermissionDenied: true
    }
    this.updateScheduledBroadcastStore = this.updateScheduledBroadcastStore.bind(this)
    this.initScheduledBroadcastStore = this.initScheduledBroadcastStore.bind(this)
    this.reloadScheduledBroadcastStore = this.reloadScheduledBroadcastStore.bind(this)
  }
  reloadScheduledBroadcastStore = (key, value) => {
    this.initScheduledBroadcastStore(this.state.scheduledBroadcastStore.scheduledBroadcastId)
  }
  updateScheduledBroadcastStore = (key, value) => {
    let myScheduledBroadcastStore = this.state.scheduledBroadcastStore // faster deep copy 
    myScheduledBroadcastStore[key] = value
    this.setState({ scheduledBroadcastStore: myScheduledBroadcastStore })
    // let scheduledBroadcastStore = { ...this.state.scheduledBroadcastStore }
    // scheduledBroadcastStore[key] = value
    // this.setState({ scheduledBroadcastStore })
  }
  initScheduledBroadcastStore = async (scheduledBroadcastId) => {
    let apirUrl = config.api.scheduledBroadcastDetail.url
    apirUrl = apirUrl.replace('___SCHEDULEDBROADCASTID___', scheduledBroadcastId)
    let apiResponseNetwork
    try {
      apiResponseNetwork = await superagent
        .get(apirUrl)
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('X-JWT', window.localStorage.getItem('JWT'))
        .withCredentials()
      // .send({})
    } catch (error) { // network error && 401 not authenticated
      return
    }

    let responseKeepapi = apiResponseNetwork.body
    if (responseKeepapi.data.length > 0) {
      let scheduledBroadcastStore = responseKeepapi.data[0]
      this.setState({ scheduledBroadcastStore, scheduledBroadcastStoreRightPermissionDenied: false })
    }
  }

  render() {
    return (
      <KMScheduledBroadcastContext.Provider value={this.state}>
        {this.props.children}
      </KMScheduledBroadcastContext.Provider>
    )
  }
}

export const withScheduledBroadcastStore = Component => props => (
  <KMScheduledBroadcastContext.Consumer>
    {store => {
      return (<Component {...props} {...store} />)
    }
    }
  </KMScheduledBroadcastContext.Consumer>
)

/** * HOW TO USE IT
import React, { Component } from "react";
import { withScheduledBroadcast } from 'stores/KMScheduledBroadcastProvider'

class TOTO extends Component {
  render () {
    return (<h1>Hello {this.props.scheduledBroadcast.firstName}!</h1>)
  }
}
export default withScheduledBroadcast(TOTO)

 */
