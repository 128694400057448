import React, { createContext, Component } from 'react'
import config from 'config'
import superagent from 'superagent'

export const KMEstablishmentContext = createContext({
  establishmentStore: {}, // store all the establishment details
  updateEstablishmentStore: () => { },
  initEstablishmentStore: () => { },
  reloadEstablishmentStore: () => { },
  establishmentStoreRightPermissionDenied: true
})

export class KMEstablishmentProvider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      establishmentStore: {}, // store all the establishment details
      updateEstablishmentStore: this.updateEstablishmentStore,
      initEstablishmentStore: this.initEstablishmentStore,
      reloadEstablishmentStore: this.reloadEstablishmentStore,
      establishmentStoreRightPermissionDenied: true
    }
    this.updateEstablishmentStore = this.updateEstablishmentStore.bind(this)
    this.initEstablishmentStore = this.initEstablishmentStore.bind(this)
    this.reloadEstablishmentStore = this.reloadEstablishmentStore.bind(this)
  }
  reloadEstablishmentStore = (key, value) => {
    this.initEstablishmentStore(this.state.establishmentStore.establishmentId)
  }
  updateEstablishmentStore = (key, value) => {
    let myEstablishmentStore = this.state.establishmentStore // faster deep copy 
    myEstablishmentStore[key] = value
    this.setState({ establishmentStore: myEstablishmentStore })
    // let establishmentStore = { ...this.state.establishmentStore }
    // establishmentStore[key] = value
    // this.setState({ establishmentStore })
  }
  initEstablishmentStore = async (establishmentId) => {
    let apirUrl = config.api.establishmentDetail.url
    apirUrl = apirUrl.replace('___ESTABLISHMENTID___', establishmentId)
    let apiResponseNetwork
    try {
      apiResponseNetwork = await superagent
        .get(apirUrl)
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('X-JWT', window.localStorage.getItem('JWT'))
        .withCredentials()
      // .send({})
    } catch (error) { // network error && 401 not authenticated
      return
    }

    let responseKeepapi = apiResponseNetwork.body
    if (responseKeepapi.data.length > 0) {
      let establishmentStore = responseKeepapi.data[0]
      this.setState({ establishmentStore, establishmentStoreRightPermissionDenied: false })
    }
  }

  render() {
    return (
      <KMEstablishmentContext.Provider value={this.state}>
        {this.props.children}
      </KMEstablishmentContext.Provider>
    )
  }
}

export const withEstablishmentStore = Component => props => (
  <KMEstablishmentContext.Consumer>
    {store => {
      return (<Component {...props} {...store} />)
    }
    }
  </KMEstablishmentContext.Consumer>
)

/** * HOW TO USE IT
import React, { Component } from "react";
import { withEstablishment } from 'stores/KMEstablishmentProvider'

class TOTO extends Component {
  render () {
    return (<h1>Hello {this.props.establishment.firstName}!</h1>)
  }
}
export default withEstablishment(TOTO)

 */
